<template>
  <el-dialog custom-class="minWidthLandAdd" title="隐私政策" :visible.sync="visible" :close-on-click-modal="false" @close="onClose" draggable>
    <div style="text-align: left">
      <div style="line-height: 30px;">
        <p>【引言】</p>
        <p>德阳市土地二级市场交易平台非常重视保护您的隐私。</p>
        <p>
          &nbsp;&nbsp;&nbsp;尊敬的德阳市土地二级市场交易平台用户（以下统称“您”）,欢迎您使用由德阳市自然资源和规划局主办,德阳市自然资源和规划局负责管理的德阳市土地二级市场交易平台。为方便您登录、使用相关服务,以及为您提供更个性化的用户体验和服务,您在使用我们的服务时,我们可能会收集和使用您的相关信息。我们希望通过本隐私政策向您说明,在使用德阳市土地二级市场交易平台电脑端、小程序及后续更新版本或德阳市土地二级市场交易平台服务（以下统称“本平台”）时,我们如何收集、使用、储存和披露您的信息,以及我们为您提供的访问、更新、控制和保护这些信息的方式。本隐私政策与您所使用的德阳市土地二级市场交易平台服务信息相关,希望您仔细阅读。
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;您使用我们的服务,即意味着您已经同意我们按照本隐私政策收集、使用、储存和披露您的相关信息,以及向您提供的控制和保护措施。
        </p>
        <p>
          一、【适用范围】
        </p>
        <p>
          1.1 本隐私政策适用于本平台所有服务。服务包括向您提供页面浏览、网站登录服务,以及通过本平台向您提供的技术服务。
        </p>
        <p>
          1.2 需要特别说明的是,作为本平台的用户,若您利用本平台的技术服务,为您的用户再行提供服务,因您的业务数据属于您所有,您应当另行与您的用户约定隐私政策。
        </p>
        <p> 1.3本隐私政策中的“信息”特指第二条所收集的信息。</p>

        <p> 二、【信息收集范围】</p>
        <p> 2.1 为完成本平台账号的注册、管理、实名认证等必要活动,您需要提交真实、合法、有效的信息,包括但不限于您的<b>姓名、身份证号码、联系电话、人脸生物信息、银行账号信息、工商登记信息等</b>。</p>
        <p> 2.2 您使用本服务时,可能收集已经经过您授权获取或主动填写的如下信息:</p>
        <p> 2.2.1日志信息,指您使用我们的服务时,系统可能通过自动采集的技术信息,包括</p>
        <p> &nbsp;&nbsp;(1)设备或软件信息,例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码。</p>
        <p> &nbsp;&nbsp;(2)有关您曾使用的移动应用和其他软件的信息,以及您曾经使用该等移动应用和软件的信息。</p>
        <p> &nbsp;&nbsp;(3)您通过我们的服务进行通讯的信息,例如曾通讯的账号。</p>
        <p> &nbsp;&nbsp;(4)您通过我们的服务分享的内容所包含的信息（元数据）,例如拍摄或上传的共享照片或录像的日期、时间或地点等。</p>
        <p> 2.2.2位置信息,指您开启设备定位功能并使用我们基于位置提供的相关服务时,收集的有关您位置的信息,包括:</p>
        <p>  &nbsp;&nbsp;(1)您通过具有定位功能的移动设备使用我们的服务时,通过GPS或WiFi等方式收集的您的地理位置信息。</p>
        <p>  &nbsp;&nbsp;(2)您或其他用户提供的包含您所处地理位置的实时信息,例如您提供的账号信息中包含的您所在地区信息,您上传的显示您当前或曾经所处地理位置的共享信息,您或其他人共享的照片包含的地理标记信息。</p>
        <p>  &nbsp;&nbsp;(3)您可以通过关闭定位功能,停止对您的地理位置信息的收集。</p>
        <p> 2.3 如果您需要使用部分可选的功能或服务,您还应该提供使用该功能或服务所必须的信息,如姓名、身份证号码、联系电话、人脸生物信息等。</p>
        <p> 2.4 如果您需要在线上申请线下交付或进行产品测试,为履行所必需,您应提供联系人、联系方式、地址等必要信息。</p>
        <p> 2.5 当我们要将信息用于本隐私政策未载明的其他用途,将基于特定目的收集而来的信息用于其他目的时,会事先征求您的同意。</p>
        <p> 三、【如何收集信息】</p>
        <p> 3.1 我们通过您主动提交,以及在您使用我们的服务过程中产生相应的记录等方式收集您的信息。</p>
        <p> 3.2 我们可能会通过 Cookies 和其他相关技术收集和使用您的信息。我们使用 Cookies 的具体用途包括:</p>
        <p> &nbsp;&nbsp;(1)记住您的身份。例如:Cookies 有助于我们辨认您作为我们的注册用户的身份。</p>
        <p> &nbsp;&nbsp;(2)分析您使用我们服务的情况,以便为您提供更加周到的个性化服务,包括定制化页面、推荐等服务。</p>
        <p> 3.3 您可以通过浏览器设置拒绝或管理 Cookies。但请注意,如果停用 Cookies,您有可能无法享受最佳的服务体验,某些功能的可用性可能会受到影响。</p>
        <p> 3.4 通过本平台 Cookies 记录的有关信息,将适用本隐私政策。</p>
        <p> 四、【信息使用】</p>
        <p> 4.14.1为了向您提供更加优质、便捷、安全的服务,在符合相关法律法规的前提下,我们可能将收集的信息用作以下用途:</p>
        <p> &nbsp;&nbsp;(1)向您提供服务（如实名认证信息、用来接受验证码的手机号,是继续获得服务的前提）;</p>
        <p> &nbsp;&nbsp;(2)满足您的个性化需求。例如,语言设定、位置设定、个性化的帮助服务和指示,或对您和其他用户作出其他方面的回应。</p>
        <p> &nbsp;&nbsp;(3)服务优化和开发。例如,我们会根据本平台系统响应您的需求时产生的信息,优化我们的服务。</p>
        <p> &nbsp;&nbsp;(4)保护您或其他用户或公众的人身财产安全免遭侵害,更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险,更准确地识别违反法律法规或本平台相关协议、规则的情况,我们可能使用您的账号信息、并整合设备信息、有关网络日志以及我们关联公司、合作伙伴分享的信息,来判断您账号风险、进行身份验证、安全事件的检测及防范,并依法采取必要的记录、审计、分析、处置措施。 例如,我们会将您的信息用于身份验证、安全防范、投诉处理、纠纷协调、诈骗监测等用途。您在使用安全功能或其他类似服务时,我们会对恶意程序或病毒进行检测,或为您识别诈骗信息。</p>
        <p> &nbsp;&nbsp;(5)向您提供与您更加相关的服务。例如,向您提供您可能感兴趣的类似功能或服务等。</p>
        <p> &nbsp;&nbsp;(6)邀请您参与有关我们产品和服务的调查。</p>
        <p> &nbsp;&nbsp;(7)其他可能需要使用收集的信息的相关场景,如果使用场景与初始场景无合理的关联性,我们会在使用信息前重新征得您的同意。</p>
        <p> 4.2在收集您的用户信息后,我们将利用技术手段对您的信息进行去标识化、加密或匿名化处理,通过这些去标识化、加密或匿名化的信息将无法识别您的具体信息主体身份。</p>
        <p> 4.3 您授权同意的以及法律法规规定、监管要求的其它用途。</p>
        <p> 五、【信息共享、转让、披露】</p>
        <p> 5.1 共享</p>
        <p> 我们不会与其他组织和个人共享您的用户信息,但以下情况除外:</p>
        <p> 5.1.1 在获取明确同意的情况下共享:获得您的明确同意后,我们会与其他方共享您的用户信息;</p>
        <p> 5.1.2 在法定情形下的共享:我们可能会根据法律法规规定、诉讼、仲裁解决需要,或按行政、司法机关依法提出的要求,对外共享您的用户信息;</p>
        <p>5.1.3 为了促成办理服务或协助解决争议,某些情况下只有共享您的用户信息,才能促成办理或处理您与他人的纠纷或争议。</p>
        <p>5.1.4 与授权合作伙伴共享:我们可能委托受信赖的合作伙伴来提供服务（如人脸识别服务）,因此我们可能会与合作伙伴共享您的某些用户信息,以提供更好的客户服务和优化用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的用户信息,并且只会共享提供服务所必要的用户信息。如果我们共享您的除上述必要用户信息以外的其他用户信息,或我们合作的第三方服务方改变用户信息的使用及处理目的,我们将再次征求您的明确同意。</p>
        <p>目前,我们的授权合作伙伴包括如下类型:供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴,这些支持包括提供基础技术服务、提供咨询、分析等专业服务。</p>
        <p>对我们与之共享用户信息的公司、组织和个人,我们会与其签署严格的保密协议以及信息保护约定,要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理用户信息。</p>
        <p>5.2 转让</p>
        <p>我们不会将您的用户信息转让给任何公司、组织和个人,但以下情况除外:</p>
        <p>5.2.1在获取明确同意的情况下转让:获得您的明确同意后,我们会向其他方转让您的用户信息。</p>
        <p>5.2.2 根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供。</p>
        <p>5.3 披露</p>
        <p>未经您同意,我们不会与任何无关第三方分享您的信息,以下情形除外:</p>
        <p>5.3.1. 我们可能将您的信息与我们的关联公司、第三方服务提供商、承包商及代理、政府系统间分享，仅用作下列用途：</p>
        <p>(1)提供“二、信息收集范围”中的相应功能或服务所必需,以及出于“四、信息使用”中的部分所述目的所必需。</p>
        <p>(2)履行我们在《德阳市土地二级市场交易平台用户服务协议》或本隐私政策中的义务和行使我们的权利。</p>
        <p>如我们与任何上述第三方分享您的信息,我们将努力确保第三方在使用您的信息时遵守本政策及我们要求其遵守的其他适当的保密和安全措施。</p>
        <p>5.3.2.根据国家政策安排或法律规定,我们以及有关政府机构有可能进行组织架构调整、合并、资产转让或类似的交易,您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的要求,在转移前通知您,确保信息在转移时的机密性,以及变更后继续履行相应责任和义务。</p>
        <p>5.3.3. 我们还可能因以下原因而披露您的信息:</p>
        <p>&nbsp;&nbsp;(1)与国家安全、国防安全有关的。</p>
        <p>&nbsp;&nbsp;(2)与公共安全、公共卫生、重大公共利益直接相关的。</p>
        <p>&nbsp;&nbsp;(3)与犯罪侦查、起诉、审判和判决执行等直接相关的。</p>
        <p>&nbsp;&nbsp;(4)出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的。</p>
        <p>&nbsp;&nbsp;(5)您自行向社会公众公开的个人信息。</p>
        <p>&nbsp;&nbsp;(6)从合法公开披露的信息中收集个人信息的,如合法的新闻报道、政府信息公开等渠道。</p>
        <p>&nbsp;&nbsp;(7)经过您合法授权的情形。</p>
        <p>&nbsp;&nbsp;(8)与我们履行法律法规规定、监管要求以及行业主管部门规定的义务相关的其他情形。</p>
        <p>如我们因上述原因而披露您的信息,我们将在遵守法律法规相关规定及本政策的基础上及时告知您。</p>
        <p>六、【信息存储】</p>
        <p>6.1 本平台收集的有关您的信息保存在本平台位于中国大陆的服务器。</p>
        <p>6.2 通常,我们仅在为您提供服务期间保留您的信息,保留时间不会超过满足相关使用目的所必须的时间。</p>
        <p>您在使用本平台期间,我们将持续为您保存您的用户信息。在您注销本平台账号后,我们仅在法律法规规定、监管要求的期限内,以及为实现本隐私政策声明的目的所必须的时限内保留您的用户信息。超出上述用户信息保存期限后,我们会对您的用户信息进行删除或匿名化处理。</p>
        <p>但在下列情况下,且仅出于下列情况相关的目的,我们有可能需要较长时间保留您的信息或部分信息:</p>
        <p>&nbsp;&nbsp;(1)遵守适用的法律法规等有关规定。</p>
        <p>&nbsp;&nbsp;(2)遵守法院判决、裁定或其他法律程序的要求。</p>
        <p>&nbsp;&nbsp;(3)遵守相关政府机关或其他有权机关的要求。</p>
        <p>&nbsp;&nbsp;(4)我们有理由确信需遵守法律法规等有关规定。</p>
        <p>&nbsp;&nbsp;(5)为执行相关服务协议或本隐私政策、维护社会公共利益、处理投诉/纠纷,保护我们的的合作伙伴、我们或有关政府机构、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</p>
        <p>七、【信息安全】</p>
        <p>7.1 如果您拥有本平台其他账号,在特定场景下,您的本平台账号的个人信息可能会与您在本平台拥有的其他账号信息相关联。例如,您使用微信账号注册本平台账号。</p>
        <p>7.2 我们努力保障信息安全,以防信息的丢失、不当使用、未经授权阅览或披露。</p>
        <p>&nbsp;&nbsp;(1)我们使用各种安全技术以保障信息的安全。例如,我们将通过服务器多备份、密码加密等安全措施,防止信息泄露、毁损、丢失。</p>
        <p>&nbsp;&nbsp;(2)我们建立严格的管理制度和流程以保障信息的安全。例如,我们严格限制访问信息的人员范围,并进行审计,要求他们遵守保密义务。</p>
        <p>&nbsp;&nbsp;(3)我们重视信息安全合规工作,并通过众多国际和国内的安全认证,如网络安全等级保护认证等,以业界先进的解决方案充分保障您的信息安全。</p>
        <p>但请您理解,由于技术的限制以及可能存在的各种恶意手段,在互联网环境下,即便竭尽所能加强安全措施,也不可能始终保证信息百分之百的安全。您需要了解,您接入我们的服务所用的系统和通讯网络,有可能因我们可控范围外的因素而出现问题。</p>
        <p>若发生个人信息泄露等安全事件,我们会启动应急预案,阻止安全事件扩大,按照《国家网络安全事件应急预案》等有关规定及时上报,并以发送邮件、推送通知、公告等形式告知您相关情况,并向您给出安全建议。</p>
        <p>7.3 为更有效的保障您的信息安全,我们也希望您能够加强自我保护意识。我们仅在本平台直接导致您个人信息泄露的范围内承担直接经济责任,因此,请您妥善保管您的账号信息,避免您的个人信息泄露。本平台账号有安全保护功能,但您也需要妥善保护自己的个人信息,除非您判断认为必要的情形下,不向任何第三人提供您的账号密码等个人信息。</p>
        <p>八、【访问与控制】</p>
        <p>8.1 您可以在使用我们服务的过程中,访问、修改和删除您的相关信息。您访问、修改和删除信息的方式将取决于您使用的具体服务。</p>
        <p>8.2 如您发现我们违反法律法规的规定或者双方的约定收集、使用您的信息,您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的,且无法自行更正的,您也可以要求我们更正。</p>
        <p>8.3 在访问、修改和删除相关信息时,我们可能会要求您进行身份验证,以保障账号安全。请您理解,由于技术所限、基于法律法规要求,您的某些请求可能无法进行响应。</p>
        <p>8.4 基于法律法规要求、保障信息安全等正当事由,您的部分信息可能无法访问、修改和删除。</p>
        <p>8.5 您可以自行选择撤回对某些非基本功能或服务对您信息处理的同意,并通过在线方式或前往线下窗口进行人工处理的方式注销账号;在您主动注销账号之后,我们将停止为您提供产品或服务,根据适用法律的要求删除您的个人信息,或使其匿名化处理。</p>
        <p>8.6 如您对上述权利实现存在疑问,可以根据“十二、与我们联系”中的相关联系方式与我们取得联系。</p>
        <p>九、【未成年信息保护】</p>
        <p>9.1 我们期望父母或监护人指导未成年人使用我们的服务。我们将根据国家相关法律法规的规定保护未成年人的信息的保密性及安全性。</p>
        <p>9.2 如您为未成年人,请您的父母或监护人仔细阅读本政策,并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息,请您立即终止使用我们的服务并及时通知我们,以便我们采取相应的措施。</p>
        <p>9.3 对于经父母或法定监护人同意而收集未成年人用户信息的情况,我们只会在受到法律允许、父母或法定监护人明确同意或者保护未成年人所必要的情况下使用或公开披露此信息。如果父母或监护人发现我们在未获父母或监护人同意的情况下收集了未成年人的用户信息,请通过客服热线反馈联系我们,我们会设法尽快删除相关数据。</p>
        <p>9.4 未成年人的实名认证信息受实名认证管理机构管理,基于管理机构风险控制的考虑,未成年人的实名认证可能存在无法通过的情况,在此情况下,我们将无法提供相应服务。</p>
        <p>十、【对第三方的责任声明】</p>
        <p>如您访问通过本应用接入的第三方服务或程序时,第三方可能会有自己的隐私权保护政策。当您查看第三方开发的应用程序时,这些第三方程序可能会放置他们自己的Cookie或网络Beacon,这些Cookie或网络Beacon不受我们控制,且它们的使用不受本隐私政策的约束。我们会努力要求这些主体对您个人信息采取保护措施,建议您与他们联系以获得关于他们隐私政策的详细情况。如您发现第三方开发的应用程序存在风险,建议您终止相关操作,我们将不承担您因此产生的任何损失。</p>
        <p>十一、【隐私政策的更新】</p>
        <p>我们可能适时修订本隐私政策的条款,该等修订构成本《隐私政策》的一部分。如可能造成您在本隐私政策下权利的实质减少或扩大收集、使用信息的范围等重要规则变更时,我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下,若您继续使用我们的服务,即表示同意受经修订的本隐私政策的约束。</p>
        <p>十二、【与我们联系】</p>
        <p>我们:德阳市自然资源和规划局</p>
        <p>注册地址:四川省德阳市旌阳区凯江路二段109号</p>
        <p>邮编:618000</p>
        <p>如您对本政策或其他相关事宜有疑问,您可以通过12345进行咨询或根据我们提供的指引,填写意见反馈。我们将尽快审核所涉问题,并在验证您的用户身份后予以回复。</p>
        <p>十三、【其他】</p>
        <p>13.1 本隐私政策的成立、生效、履行、解释及纠纷解决,适用中华人民共和国法律。</p>
        <p>13.2本隐私政策无论因何种原因部分无效或不可执行,都不影响本政策其它部分的效力。</p>
        <p>13.3本隐私政策中未尽事宜,按照法律法规和相关业务规则及国内通行的惯例办理。</p>
        <p>13.4因本隐私政策内容或执行发生任何争议,当事各方应协商解决;协商不成时,应向德阳市自然资源和规划局所在地人民法院提起诉讼解决。</p>

      </div>

    </div>
    <template>
			<span class="dialog-footer">
				<el-button  type="primary"  @click="visible = false">确认</el-button>
			</span>
    </template>

  </el-dialog>
</template>

<script setup  name="needAdd">
import { useDict } from '@/hooks/dict';
import { reactive, watch, ref, nextTick, computed, defineAsyncComponent } from 'vue'
import {Message} from 'element-ui'
import {getObj, addObj, putObj,setidCardInfo,getidCardInfo} from '@/service/CommonService';
import { rule } from '@/utils/validator';
import { preventReClick } from '@/utils/preventReClick'

const emit = defineEmits(['refresh']);
const mapDialogRef = ref();

const openMapDialog = () => {
  mapDialogRef.value.openDialog((data) => {
    console.log(data);
  });
};

// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);


const onClose= () =>{

}

// 打开弹窗
const openDialog = (id) => {
  visible.value = true;

  nextTick(() => {
    dataFormRef.value?.resetFields();
  });
  // 获取landInfo信息
};




const forms = reactive({
  timer:null,
  userverify:false
})

// 暴露变量
defineExpose({
  openDialog,
  stop
});
</script>

<style>
.minWidthLandAdd{
  min-width: 1260px !important;
}
</style>
